/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body,
html {
  margin: 0 auto;
  height: 100% !important;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
.app-green-theme {
  $my-app-primary: mat-palette($mat-green, 100);
  $my-app-accent: mat-palette($mat-green, 500);
  $my-app-warn: mat-palette($mat-green, 900);
  // Create the theme object (a Sass map containing all of the palettes).
  $my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  @include angular-material-theme($my-app-theme);
}
